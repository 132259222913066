<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <!-- Botón para salir -->
        <button class="modal-default-button" @click="close">✕</button>

        <h3>Confirmación de Envío</h3>
        <p>¿Estás seguro de que deseas enviar este mensaje masivo?</p>
        <p>Escribe "ENVIAR" para confirmar:</p>
        <input
          v-model="confirmationText"
          type="text"
          placeholder="ENVIAR"
          class="confirmation-input"
        />
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        <div class="modal-footer">
          <button class="btn btn-danger" @click="close">Cancelar</button>
          <button class="btn btn-success" @click="confirm">Confirmar</button>
          <!-- Botón Confirmar a la derecha -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalBulkMessage',
  data() {
    return {
      confirmationText: '',
      errorMessage: '',
    }
  },
  methods: {
    confirm() {
      if (this.confirmationText.toUpperCase() === 'ENVIAR') {
        this.errorMessage = ''
        this.$emit('confirmEmit')
        this.$emit('close')
      } else {
        this.errorMessage = 'Debes escribir "ENVIAR" para confirmar'
      }
    },
    close() {
      this.errorMessage = ''
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.error-message {
  color: #f44336;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.modal-container {
  width: 400px;
  padding: 35px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif;
}

.modal-header {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.modal-body {
  font-size: 14px;
  line-height: 1.6;
  color: #555555;
}

.confirmation-input {
  width: 100%;
  padding: 5px;
  border: 2px solid #cccccc;
  border-radius: 6px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.confirmation-input::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-style: italic;
}

.modal-footer {
  display: flex;
  justify-content: flex-end; /* Alinea los botones a la derecha */
  margin-top: 20px;
}

.modal-footer button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Espacio entre botones */
}

.modal-footer button.btn-success {
  background-color: #4caf50;
  color: white;
}

.modal-footer button.btn-success:hover {
  background-color: #45a049;
}

.modal-footer button.btn-danger {
  background-color: #f44336;
  color: white;
}

.modal-footer button.btn-danger:hover {
  background-color: #d32f2f;
}

.modal-mask {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
</style>
