import http from '@/utils/http'
export default {
  getMessages(slug, page) {
    return new Promise((resolve, reject) => {
      http
        .get(`rooms/${slug}/messages?page=${page}&size=30`)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  sendBulkMessage(phoneId, template, fileData, executiveEmail) {
    return new Promise((resolve, reject) => {
      console.log(fileData)
      const formData = new FormData()
      formData.append('template_id', template)
      formData.append('file', fileData)
      formData.append('executive_email', executiveEmail)
      console.log('FormData:', formData) // Verifica que el parámetro esté en el FormData
      http
        .post(`phones/${phoneId}/bulk_message`, formData, {
          template_id: template,
          file: fileData,
          executive_email: executiveEmail, // Enviar como campo simple
        })
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
}
